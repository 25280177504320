import React from "react";

export default function Experience() {
  return (
    <div className="flex flex-col items-center m-10 ">
      <div className="text-4xl font-bold font-heading md:text-5xl text-gray-800">
        <span className="reveal">Experience</span>
      </div>
      <div className="max-w-screen-lg md:w-8/12 mt-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div className="text-xl font-bold text-gray-800">
            Software Engineer
          </div>
          <div className="text-base text-gray-400">September 2023-Present</div>
        </div>
        <div className="text-base italic text-gray-800">WSP in Canada</div>
        <div className="mt-1 text-gray-800">
          <ul className="list-disc list-inside text-gray-800">
            <li>
              Contributed to the re-design of a weather portal using ReactJs,
              improving the overall user experience and modernizing the
              interface.
            </li>
            <li>
              Led efforts in converting legacy codebases to ReactJs, enhancing
              maintainability and scalability of the application.
            </li>
            <li>
              Identified and resolved bugs, and developed new features from the
              ground up, ensuring timely and successful deployments.
            </li>
            <li>
              Optimized and updated SQL stored procedures, improving database
              performance and accuracy of query results.
            </li>
            <li>
              Took ownership of an in-house product, managing it through to
              completion with successful implementation and delivery.
            </li>
            <li>
              Developed a notification service for a mobile application and
              weather portal utilizing AWS, improving real-time communication
              with users.
            </li>
            <li>
              Designed and implemented new features for a mobile application,
              contributing to the enhancement of user engagement and
              functionality.
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-screen-lg md:w-8/12 mt-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div className="text-xl font-bold text-gray-800">
            Software Engineer
          </div>
          <div className="text-base text-gray-400">May 2021-July 2023</div>
        </div>
        <div className="text-base italic text-gray-800">Kwanso</div>
        <div className="mt-1 text-gray-800">
          <ul className="list-disc list-inside text-gray-800">
            <li>
              Designed and maintained backend services and API's ( RESTful and
              GraphQL ) using NodeJS frameworks, specifically ExpressJS and
              NestJS
            </li>
            <li>
              Evaluated migration of non-relational data to relational data and
              programmed a migration script to migrate data from MongoDB to
              Postgres Database
            </li>
            <li>
              Designed Test cases for APIs and Client-side utilizing Jest and
              Cypress to ensure robust code performance, compatibility and
              security
            </li>
            <li>
              Worked closely with the engineering manager to improve the
              scalability of an already developed system by 40%, remodeling the
              database design
            </li>
            <li>
              Built a client-side for an admin dashboard from scratch using
              NextJs and Typescript
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-screen-lg md:w-8/12 mt-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div className="text-xl font-bold text-gray-800">
            Backend Developer
          </div>
          <div className="text-base text-gray-400">Mar 2021-May 2021</div>
        </div>
        <div className="text-base italic text-gray-800">Conzia GmbH</div>
        <ul className="list-disc list-inside text-gray-800">
          <li>
            Maintained and improved a real-time chat application by developing a
            multi channel widget for 3rd party websites
          </li>
          <li>
            Explained deployment issues to the Product Manager and CEO, and
            automated the deployment process using AWS services ( Amplify and
            EBS )
          </li>
          <li>
            Integrated and designed new backend services to introduce paid
            features such as live typing and read receipts for the widget
          </li>
        </ul>
      </div>
      <div className="max-w-screen-lg md:w-8/12 mt-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div className="text-xl font-bold text-gray-800">
            Full Stack Developer
          </div>
          <div className="text-base text-gray-400">Nov 2019-Mar 2021</div>
        </div>
        <div className="text-base italic text-gray-800">Brandpa</div>
        <ul className="list-disc list-inside text-gray-800">
          <li>
            Oversaw and contributed to the development of two in-house products
            for the company
          </li>
          <li>
            Programmed and built backend services using ExpressJS and integrated
            those services on Client-side which was developed using ReactJS
          </li>
          <li>Maintained and deployed both products using AWS services</li>
        </ul>
      </div>
    </div>
  );
}
