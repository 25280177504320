import React from "react";
import github from "../images/Github.png";
import linkin from "../images/LinkedIn.png";
// import twitter from "../images/Twitter.png";
import whatsapp from "../images/whatsapp.svg";

export default function Contact() {
  return (
    <div className="flex flex-col items-center m-10">
      <div className="text-4xl font-bold font-heading md:text-5xl text-gray-800">
        <span className="reveal">Say Hello!</span>
      </div>
      <div className="max-w-screen-lg  mt-2 md:w-8/12">
        <div className="text-lg text-gray-800">
          If you’d like to send me a message, I can be reached through various
          ways! If you prefer email, simply fill out the form below or send me a
          message directly at{" "}
          <code className="font-bold bg-gray-200">faizansaleem790@gmail.com</code>
          .
        </div>
        <div className="mt-5">
          <form className="" action="https://send.pageclip.co/P9QagMdv15eLwkaysfM8cuRnvKpTvHAv" method="post">
            <div className="mt-2 flex flex-col">
              <label for="sender" className="text-lg font-medium text-gray-800">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="sender"
                className="mt-1 h-10 pl-2 text-lg text-gray-800 bg-gray-400 border-b-2 border-gray-800"
              />
            </div>
            <div className="mt-2 flex flex-col">
              <label
                for="sender-email"
                className="text-lg font-medium text-gray-800"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="sender-email"
                value={'faizansaleem790@gmail.com'}
                disabled={true}
                className="mt-1 h-10 pl-2 text-lg text-gray-800 bg-gray-400 border-b-2 border-gray-800"
              />
            </div>
            <div className="mt-2 flex flex-col">
              <label
                for="message"
                className="text-lg font-medium text-gray-800"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                cols="33"
                className="mt-1 h-36 pl-2 text-lg text-gray-800 bg-gray-400 border-b-2 border-gray-800"
              />
            </div>
            <div className="mt-3 flex flex-col items-end">
              <button
                type="submit"
                className="pageclip-form__submit w-490 p-2 uppercase bg-transparent border-2 border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white"
              >
                <span>
                  Send Message
                </span>
              </button>
            </div>
          </form>
        </div>
        <div className="my-10">
          <div className="text-xl text-center font-semibold text-gray-800">
            I can also be found at the following
          </div>
          <div className="flex justify-center mt-3 space-x-3 text-gray-500 items-center">
            {/* <a href="https://github.com/faizansaleem-dev" target="_blank" rel="noreferrer">
              <img
                src={github}
                alt=""
              />
            </a> */}
            <a href="https://linkedin.com/in/faizansaleem95" target="_blank" rel="noreferrer">
              <img
                src={linkin}
                alt=""
              />
            </a>
            {/* <a href="https://twitter.com/Capt4ainBear?t=63qI95fXIoXeT-xG6Pru1Q&s=09" target="_blank" rel="noreferrer">
              <img
                src={twitter}
                alt=""
              />
            </a> */}
            <a href="https://wa.me/923334429789" target="_blank" rel="noreferrer">
              <img
                src={whatsapp}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
