import React from "react";
import Picture from '../images/myPic .jpeg';
import Character from "./Character";

export default function Home() {
  return (
    <div className="flex items-center justify-center font-mono relative overflow-hidden md:mt-16">
      <div className="flex relative items-center">
        <div className="container mx-auto px-6 flex flex-col justify-between items-center relative py-4">
          <div className="flex flex-col">
            
            <img
              src={Picture}
              alt=""
              className="rounded-full w-28 mx-auto"
            />
            <p className="text-3xl my-6 text-center text-gray-800 dark:text-white relative">
              Hi, I&#x27;m Faizan{" "}
              <Character/>
            </p>
            <h2 className="max-w-3xl text-5xl md:text-6xl font-bold mx-auto dark:text-white text-gray-800 text-center py-2">
              <span className="reveal">Software Engineer </span>
              <span className="reveal">working on Web </span> 
              <span className="reveal">Development.</span> 
              {/* Building digital products, brands, and experiences. */}
            </h2>
            <div className="flex items-center flex-col justify-center mt-4">
              <a
                href="/projects"
                className="uppercase py-2 my-2 px-4 bg-transparent dark:text-gray-800 dark:bg-white hover:dark:bg-gray-100 border-2 border-gray-800 text-gray-800 dark:text-white hover:bg-gray-800 hover:text-white text-md"
              >
                View my work
              </a>
              <a
                href="/contact"
                className="uppercase py-2 my-2 px-4 bg-transparent dark:text-gray-800 dark:bg-white hover:dark:bg-gray-100 border-2 border-gray-800 text-gray-800 dark:text-white hover:bg-gray-800 hover:text-white text-md"
              >
                CONNECT WITH ME
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
