import React from "react";

export default function Education() {
  return (
    <div className="flex flex-col items-center m-10 ">
      <div className="text-4xl font-bold font-heading md:text-5xl text-gray-800">
        <span className="reveal">Education</span>
      </div>
      <div className="max-w-screen-lg md:w-8/12 mt-1">
        <div className="text-xl md:text-3xl text-gray-800">
          Masters in Computer Science
        </div>
        <a href="https://umt.edu.pk" target="_blank" rel="noreferrer">
          <div className="text-base italic text-gray-800">
            Memorial University of Newfoundland and Labrador
          </div>
        </a>
        <div className="text-sm text-gray-400">2022-2024</div>
        {/* <p className="text-base text-gray-800 mt-2">
          Some Courses Taken:
        </p> */}
        {/* <div className="mt-1">
          <ul className="text-base list-disc list-inside text-gray-800">
            <li>Applied Algorithms</li>
          </ul>
        </div> */}
        <p className="text-base mt-2 text-gray-800">
          Currently enrolled at Memorial University.
        </p>
      </div>
      <br></br>
      <div className="max-w-screen-lg md:w-8/12 mt-1">
        <div className="text-xl md:text-3xl text-gray-800">
          BSc Computer Science
        </div>
        <a href="https://umt.edu.pk" target="_blank" rel="noreferrer">
          <div className="text-base italic text-gray-800">
            University of Management and Technology
          </div>
        </a>
        <div className="text-sm text-gray-400">2015-2019</div>
        <p className="text-base text-gray-800 mt-2">Some Courses Taken:</p>
        <div className="mt-1">
          <ul className="text-base list-disc list-inside text-gray-800">
            <li>Artificial Intelligence</li>
            <li>Machine Learning</li>
            <li>Software Engineering</li>
            <li>Digital Image Processing</li>
            <li>Web Technologies</li>
          </ul>
        </div>
        <p className="text-base mt-2 text-gray-800">
          For my final year project, I worked with two team members to create an
          autonomus car cleaning robot. We used NodeMCU and Arduino to function
          as the brain of the robot. We created a navigation system for the
          robot to move around the car using sensors. We also managed to create
          an arm design which was flexible and firm in posture to reach
          different parts of the car while also being able to withstand high
          pressure.
        </p>
      </div>
    </div>
  );
}
