import illumidesk from "../images/illumidesk.svg"
import conzia from "../images/conzia.png"
import unigamer from "../images/unigamer.svg"
import optum from '../images/optum.svg'
import idid from "../images/idid.png"
import omnigear from "../images/omnigear.png"
import uptok from "../images/uptok.png"
export const projects = [
    {
        title: 'Optum Store',
        imgSrc: optum,
        siteURL: 'https://store.optum.com/',
        details: 'Optum store is an e-commerce platform provided by Optum Company, which focuses on providing pharmacy services through an e-commerce platform.',
        techStack: ['ReactJs', 'Typescript', 'CircleCi', 'NodeJs', 'Jest']
    },
    {
    title: 'Illumidesk',
    imgSrc: illumidesk,
    siteURL: 'https://www.illumidesk.com/',
    details: `IllumiDesk is an interactive environment that allows you to create and share documents
    that contain live code, equations, visualizations, and explanatory text.
    Jupyter notebooks can incorporate markdown elements, allowing you to easily create
    readable reports that include equations and rich media, with its admin dashboard you can create virtual campuses and
    manage resources.`,
    techStack: ['ReactJs', 'NextJs', 'Typescript', 'MongoDB', 'ExpressJs', 'Nbgrader', 'Python', 'Cypress']
}, {
    title: 'Uptok',
    imgSrc: uptok,
    siteURL: 'https://www.uptok.com/',
    details: `A video commerce platform that lets you conduct 1-on-1 shopping sessions with the highest-intent shoppers on your shopify 
    store or any other e-commerce site. 
    Conduct live demos, suggest products and offer discounts—all in one seamless experience.`,
    techStack: ['ExpressJs', 'PostgreSql', 'S3', 'Typescript', 'Shopify', 'Heroku', 'Socket.io']
}, {
    title: 'Live Chat, Facebook, Messenger',
    imgSrc: conzia,
    siteURL: 'https://apps.shopify.com/conzia?surface_detail=Live+chat&surface_inter_position=1&surface_intra_position=10&surface_type=search',
    youtubeEmbed: 'u5cGDMp1dak',
    details: `This is a chat application which allows shopify store owners to
    communicate with their customers by having a chat widget provided by Conzia on their stores. My role here was to develop API's 
    to provide services to be integrated on client side as well as streamline the deployment procedure and implement CI CD for automated
    updates to be deployed on server.`,
    techStack: ['ReactJs', 'NextJs', 'Typescript', 'Javascript', 'MongoDB', 'NestJs', 'AWS',  'Socket.io']
}, {
    title: 'Unigamer',
    imgSrc: unigamer,
    siteURL: 'https://www.unigamer.com/',
    details: `Unigamer is a social platform for gamers to connect and share their gaming experience with each
    other. This was a freelance project I contributed to as a backend developer`,
    techStack: ['NestJS', 'MongoDB', 'AWS', 'Typescript']
}, {
    title: 'Omnigear',
    imgSrc: omnigear,
    details: `Omnigear is an in-house product for Brandpa, Omnigear serves as POS for shopify platform.
    Omnigear has an easy to user dashboard from which you can manage your inventory, orders and shipping services. 
    Omnigear gives useful business analytics for the store to improve sales. We also provide separate dashboards on request
    for customer support executives with limited access to resources`,
    techStack: ['ExpressJs', 'ReactJs', 'AWS', 'MongoDB', 'Javascript', 'Shopify']
}, {
    title: 'iDid',
    imgSrc: idid,
    details: `iDid is an in-house product for Brandpa, Company decided to develop this product during Covid-19 time to assist startups in
    managing their work while they work from home. iDid records the employees daily activites, task assignment and a dedicated human resource
    dashboard`,
    techStack: ['ExpressJs', 'ReactJs', 'MongoDB', 'AWS', 'Javascript']
}]