import React, { useState } from 'react'

import typescript_logo from "../images/Typescript.png"
import javascript_logo from "../images/javascript.png"
import node_js_logo from "../images/node-js-logo.jpg"
import aws_logo from "../images/aws-logo.jpg"
import nextJs_logo from "../images/next-js-logo.png"
import nestJs_logo from "../images/nest-logo.png"
import mongodb_logo from "../images/mongodb.png"
import react_logo from "../images/react-logo.png"

const Skills = () => {
  const [load, setLoad] = useState(false)

  return (
  <div className="grid place-items-center min-h-screen">
  <div className="p-4 max-w-5xl grid gap-4 xs:grid-cols-2 xs:p-8 md:grid-cols-4 lg:gap-6">
    <h1 className="text-4xl font-extrabold xs:col-span-2 xs:grid xs:gap-4 xs:grid-cols-2 md:col-span-3 md:text-5xl md:grid-cols-3 lg:text-6xl">
      <span className="md:col-span-2">Skills.</span>
    </h1>
      <p class="row-start-2 col-start-2 self-center md:col-start-1 md:col-span-2 md:text-lg"></p>
      <div className="h-28  xs:h-auto xs:square"><img src={javascript_logo} alt="logo" className="h-full w-full"/></div>
      <div className="h-28  xs:h-auto xs:square"><img src={typescript_logo} alt="logo" className="h-full w-full"/></div>
      <div className="h-28  xs:h-auto xs:square "><img src={node_js_logo} alt="logo" className="h-full w-full"/></div>
      <div className="h-28  xs:h-auto xs:square md:col-start-2 "><img src={aws_logo} alt="logo" className="h-full w-full"/></div>
      <div className="h-28  xs:h-auto xs:square"><img src={nextJs_logo} alt="logo" className="h-full w-full"/></div>
      <div className="h-28  xs:h-auto xs:square"><img src={nestJs_logo} alt="logo" className="h-full w-full"/></div>
      <div className="h-28  xs:h-auto xs:square"><img src={mongodb_logo} alt="logo" className="h-full w-full"/></div>
      <div className="h-28  xs:h-auto xs:square"><img src={react_logo} alt="logo" className="h-full w-full"/></div>
      <div className="md:col-span-2">
        <div className="flex">
          <h4 className="text-xl font-semibold">More Skills</h4>
          <div 
            onClick={() => setLoad(!load)}
            className="ml-2 animate-bounce bg-white dark:bg-gray-800 p-2 w-10 h-10 ring-1 ring-gray-900/5 dark:ring-gray-200/20 shadow-lg rounded-full flex items-center justify-center"
            >
           { 
             load ?(
              <svg className="w-6 h-6 text-violet-500 transform rotate-180" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            ) :(
              <svg className="w-6 h-6 text-violet-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            )
            }
          </div>
        </div>
        {load ?(
          <ul className={`flex flex-wrap mt-3 ${load ? 'transform transition duration-500 ease-custom': ''}`}>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">HTML/CSS</span></li>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">Github</span></li>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">EC2</span></li>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">Amplify</span></li>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">Elastic Beanstalk</span></li>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">Postgresql</span></li>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">Code Commit</span></li>
          <li className="mr-3 my-3"><span className="border-2 border-solid border-black py-2 px-3 rounded-full">Code Pipeline</span></li>
        </ul>): null
        }
      </div>

    </div>
  </div>

  )
}

export default Skills
